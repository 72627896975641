<template>
  <div class="container">
    <div class="left">
      <div class="name">
        <img class="logo" src="../../assets/imgs/logo.png" alt="" />
        <img class="comName" src="../../assets/imgs/pc/name.png" alt="" />
      </div>
      <span class="subTitle">专注于太阳能新能源类研发与安装</span>
      <div class="phone">
        <div class="iconwrap">
          <i class="icon iconfont icon-phone"></i>
        </div>
        <span class="phonecode">{{ info.phone }}</span>
      </div>
      <div class="copy">
        <a href="http://www.beian.gov.cn/" target="_blank"
          >备案号：苏ICP备20036274号-1</a
        >
        <span>{{ info.name }} 版权所有</span>
      </div>
    </div>
    <div class="center"></div>
    <div class="right"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapState(['info'])
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 50px 10%;
  .left {
    .name {
      .logo {
        width: 80px;
      }
      .comName {
        width: 200px;
      }
    }
    .subTitle {
      padding: 20px;
      line-height: 40px;
    }
    .phone {
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-top: 10px;
      .iconwrap {
        width: 30px;
        height: 30px;
        background: rgb(4, 146, 222);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        border-radius: 4px;
        margin-right: 10px;
      }
      .phonecode {
        color: rgb(4, 146, 222);
        font-size: 20px;
        font-weight: bold;
      }
    }
    .copy {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      margin-top: 30px;
      a {
        color: inherit;
      }
    }
  }
}
</style>
