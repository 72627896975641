<template>
  <div class="container">
    <div
      class="menuItem"
      v-for="(item, index) in menu"
      :key="index"
      @click="toUrl(item.url, index)"
    >
      <img :src="item.icon" alt="" />
      <a :href="`tel:${info.phone}`" v-if="index === 0">{{ item.name }}</a>
      <span v-else>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  data() {
    return {
      menu: [
        {
          name: '一键拨打',
          icon: require('../../assets/imgs/mobile/footer/phone_icon.png')
        },
        {
          name: '首页',
          icon: require('../../assets/imgs/mobile/footer/home_icon.png'),
          url: '/home'
        },
        {
          name: '工程案例',
          icon: require('../../assets/imgs/mobile/footer/case_icon.png'),
          url: '/case'
        }
      ]
    }
  },
  methods: {
    toUrl(url, index) {
      if (url) {
        if (index === 1) {
          document.body.scrollTop = document.documentElement.scrollTop = 0
        }
        this.$router.push(url)
      } else {
        if (index === 0) return
        this.$Message.info('板块维护中，敬请期待')
      }
    }
  },
  computed: {
    ...mapState(['info'])
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  height: 16vw;
  background-color: #161616;
  justify-content: space-between;
  align-items: center;
  padding: 0 4vw;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  .menuItem {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 8vw;
    }
    span,
    a {
      padding-left: 2vw;
      font-size: 4vw;
      color: #fff;
    }
  }
  .menuItem:nth-child(2) {
    span {
      letter-spacing: 2vw;
    }
  }
}
</style>
