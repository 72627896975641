<template>
  <div :class="isPc ? 'pc' : 'mobile'">
    <pc-layout v-if="isPc" />
    <mobile-layout v-else />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import PcLayout from '@/layout/pc'
import MobileLayout from '@/layout/mobile'
import Util from '@/utils'
export default {
  name: 'App',
  components: { PcLayout, MobileLayout },
  data() {
    return {
      isPc: Util.isPc()
    }
  },
  methods: mapMutations(['initInfo', 'initCase']),
  created() {
    this.initInfo()
    this.initCase({
      skip: 0,
      limit: 6
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  margin: 0;
}
.pc {
  min-width: 1500px;
}
.mobile {
  max-width: 100vw;
  padding-bottom: 16vw;
  overflow: hidden;
}
</style>
