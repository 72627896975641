<template>
  <div class="container">
    <div class="left">
      <img src="../../assets/imgs/pc/sub_title.png" alt="" />
    </div>
    <div class="right">
      <div v-for="(item, index) in menus" class="menuItem" @click="toUrl(item.url)" :key="index">
        <i v-if="item.icon" :class="item.icon"></i>
        <span class="name" :class="index === 0 ? 'active' : ''">{{
          item.name
        }}</span>
        <span class="line" v-if="index !== menus.length - 1">/</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      menus: [
        {
          name: '网站首页',
          url: '/'
          // icon:'icon iconfont icon-home'
        },
        {
          name: '成功案例',
          url: '/case'
        },
        {
          name: '国家政策',
          url: ''
        },
        {
          name: '新闻中心',
          url: ''
        },
        {
          name: '关于我们',
          url: ''
        },
        {
          name: '联系我们',
          url: ''
        }
      ]
    }
  },
  methods: {
    toUrl(url) {
      if (url) {
        this.$router.push(url)
      } else {
        // this.$Message.info('板块维护中，敬请期待')
      }
    }
  }
}
</script>


<style lang="less" scoped>
.container {
  display: flex;
  .left {
    width: 34%;
    height: 50px;
    background-color: rgb(3, 148, 222);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
    pointer-events: none;
  }
  .right {
    flex: 1;
    display: flex;
    justify-self: start;
    align-items: center;
    background-color: rgb(234, 234, 234);
    padding-left: 60px;
    .menuItem {
      i {
        font-size: 24px;
        vertical-align: bottom;
      }
      .name {
        cursor: pointer;
      }
      .line {
        padding: 0 30px;
      }
    }
    .active {
      color: rgb(3, 148, 222);
    }
  }
}
</style>