
<template>
  <div class="container">
    <Header />
    <router-view class="content" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/mobile/Header'
import Footer from '@/components/mobile/Footer'

export default {
  name: 'MobileContainer',
  components: { Header, Footer }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  max-width: 100vw;
  user-select: none;
  .content {
    padding-bottom: 16vw;
  }
}
</style>