import axios from 'axios'

const baseUrl = process.env.NODE_ENV === 'development' ? '/api/' : 'https://jsrrgd.cn/'

export default {
  initInfo(state) {
    axios({
      url: `${baseUrl}gf/sran/getInfo`,
      method: 'POST',
      responseType: 'json'
    }).then(res => {
      state.info = res.data.data
    })
  },
  initCase(state, params) {
    axios({
      url: `${baseUrl}gf/sran/getCase`,
      method: 'POST',
      responseType: 'json',
      data: params
    }).then(res => {
      const list = res.data.data
      state.caseList = list
    })
  }
}