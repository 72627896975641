<template>
  <div class="container">
    <div class="left">
      <img class="logo" src="../../assets/imgs/logo.png" alt="" />
      <img class="comName" src="../../assets/imgs/pc/name.png" alt="" />
      <div class="desc">
        <span>打造新能源产业优质服务</span>
        <span>专注于太阳能新能源类研发与安装</span>
      </div>
    </div>
    <div class="right">
      <img src="../../assets/imgs/pc/phone_ico.png" alt="" />
      <div class="phone">
        <span class="title">咨询服务热线</span>
        <span class="phoneCode">{{ info.phone }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Header',
  computed: {
    ...mapState(['info'])
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 10px 10px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
    .logo {
      width: 100px;
    }
    .comName {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      padding-right: 10px;
    }
    .desc {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      color: rgb(115, 115, 115);
      span:nth-child(1) {
        font-size: 25px;
        color: rgb(7, 162, 242);
        font-weight: bold;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
    }
    .phone {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      .title {
        color: rgb(115, 115, 115);
      }
      .phoneCode {
        font-family: 'Impact', 'Charcoal', 'sans-serif';
        color: rgb(7, 162, 242);
        font: bold 30px/30px normal;
      }
    }
  }
}
</style>
