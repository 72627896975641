<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/pc/Header'
import Menu from '@/components/pc/Menu'
import Footer from '@/components/pc/Footer'
export default {
  name: 'PcContainer',
  components: { Header, Menu, Footer }
}
</script>
