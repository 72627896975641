export default [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/view/pc/home')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import(/* webpackChunkName: "case" */ '@/view/pc/case')
  }
]