<template>
  <div class="container">
    <div class="left">
      <img src="../../assets/imgs/logo.png" alt="" />
      <img src="../../assets/imgs/name.png" alt="" />
    </div>
    <div class="right" @click="onClickMenu">
      <span>导<br />航</span>
      <div class="menuWrap">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
    <div class="MenuWrap" v-show="showMenu">
      <div
        class="MenuItem"
        v-for="(item, index) in menu"
        :key="index"
        @click="toUrl(item.url)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      menu: [
        {
          name: '网站首页',
          url: '/'
        },
        {
          name: '成功案例',
          url: '/case'
        },
        {
          name: '国家政策',
          url: ''
        },
        {
          name: '新闻中心',
          url: ''
        },
        {
          name: '关于我们',
          url: ''
        },
        {
          name: '联系我们',
          url: ''
        }
      ],
      showMenu: false
    }
  },
  methods: {
    onClickMenu() {
      if (this.showMenu) {
        this.showMenu = false
      } else {
        this.showMenu = true
      }
    },
    toUrl(url) {
      if (url) {
        this.$router.push(url)
      } else {
        this.$Message.info('板块维护中，敬请期待')
      }
      this.showMenu = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 15vw;
  padding: 2vw 4vw;
  display: flex;
  justify-content: space-between;
  position: relative;
  .left {
    display: flex;
    justify-content: flex-start;
    img:nth-child(1) {
      width: 14vw;
    }
    img:nth-child(2) {
      width: 44vw;
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      line-height: 14px;
      font-weight: bold;
      padding-right: 1vw;
    }
    .menuWrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .line {
        height: 0.8vw;
        width: 6vw;
        border-radius: 0.4vw;
        background-color: rgb(3, 148, 222);
      }
      .line:nth-child(2) {
        margin: 6px 0;
      }
    }
  }
  .MenuWrap {
    position: absolute;
    right: 0;
    top: 100%;
    padding: 4vw 8vw;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    .MenuItem {
      color: #fff;
      padding: 2vw 0;
    }
  }
}
</style>